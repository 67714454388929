import React, { useEffect, useState } from 'react';
import { ChatButton, FollowButton } from 'starklink';
import { Button, Select, Form, Input } from 'antd';
import type { WalletType } from '@web3mq/client';

import '@web3mq/react-components/dist/css/index.css';
import 'starklink/dist/css/index.css';
import './App.css';
const { Option } = Select;
function App() {
  const [ address, setAddress ] = useState('');
  const [ walletType, setWalletType ] = useState<WalletType>();
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const onFinish = (value: any) => {
    setAddress(value.address);
    setWalletType(value.walletType);
  }

  return (
    <div className='app_container'>
      <div className='form_box'>
        {!address && !walletType && <Form
          {...layout}
          style={{ width: 400 }}
          onFinish={onFinish}
        >
          <Form.Item
            label="address"
            name="address"
            rules={[{ required: true, message: 'Please input address!' }]}
          >
            <Input placeholder='input address you want to follow' />
          </Form.Item>

          <Form.Item
            label="walletType"
            name="walletType"
            rules={[{ required: true, message: 'Please input walletType!' }]}
          >
            <Select
              placeholder="Select walletType"
              allowClear
            >
              <Option value="eth">eth</Option>
              <Option value="starknet">starknet</Option>
            </Select>
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              to follow or chat
            </Button>
          </Form.Item>
        </Form>}
        {address && walletType && (
          <div className='button_box'>
            <FollowButton targetWalletAddress={address} targetWalletType={walletType as WalletType}/>
            <div style={{width: '5px'}} />
            <ChatButton targetWalletAddress={address} targetWalletType={walletType as WalletType} />
            <div style={{width: '5px'}} />
            <Button type="primary" onClick={() => {setAddress('');setWalletType('' as WalletType)}}>Back</Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
